// ... your code that runs before foundation initiation

// Fix for foundation stylesheets being picked up as "null" or "not an object",
// implementation from here: http://foundation.zurb.com/forum/posts/3189-foundation-5-orbit-slider-ie8-issue
// (function($) {
//     'use strict';

//     if (!Foundation.stylesheet) {
//         Foundation._style_element = $('<style></style>').appendTo('head')[0];
//         Foundation.stylesheet = Foundation._style_element.styleSheet;

//         if (Foundation.stylesheet) {
//             Foundation.stylesheet.cssRules = {
//                 length: 0
//             };

//             Foundation.stylesheet.insertRule = function(rule, index) {
//                 var media;
//                 var mediaMatch;
//                 var mediaRegex;
//                 var namespace;
//                 var ruleMatch;
//                 var ruleRegex;
//                 mediaRegex = /^\s*@media\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 mediaMatch = mediaRegex.exec(rule);
//                 media = '';

//                 if (mediaMatch) {
//                     media = '@media ' + mediaMatch[1] + ' ';
//                     rule = mediaMatch[2];
//                 }

//                 ruleRegex = /^\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 ruleMatch = ruleRegex.exec(rule);
//                 namespace = '' + media + ruleMatch[1];
//                 rule = ruleMatch[2];

//                 return this.addRule(namespace, rule);
//             };
//         } else if (window.console) {
//             console.log('Could not fix Foundation CSS rules...');
//         }
//     }
// })(jQuery);

// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

function HCTest() {
    'use strict';

    var objDiv;
    var strColor;
    var objFlag;

    // reference to img element used to check if images are disabled
    objFlag = document.getElementById('flag');
    if (objFlag === null) {
        return;
    }

    // Create a test div
    objDiv = document.createElement('div');

    // Set its color style to something unusual
    objDiv.style.color = 'rgb(31,41,59)';

    // Attach to body so we can inspect it
    document.body.appendChild(objDiv);

    // Use standard means if available, otherwise use the IE methods

    strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

    // get rid of extra spaces in result
    // strColor = strColor.replace(/ /g,"");

    // Delete the test DIV

    document.body.removeChild(objDiv);

    // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
    // Use .offsetwidth and .readyState (for IE) to check if images are enabled
    // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

    if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

        var objHead = document.getElementsByTagName('head');
        var objCSS = objHead[0].appendChild(document.createElement('link'));
        objCSS.rel = 'stylesheet';
        objCSS.href = '/media/css/alt.css';
        // objCSS.type = 'text/css';
    }
}

// Safely initiate foundation now
$(document).foundation();

// the rest of your code

$(window).load(function() {
    'use strict';

    // accessible image sprites
    HCTest();
});

// mobile-nav
(function() {

    var mqm = window.matchMedia('(max-width: 46.3125em)');

    // get the button and menu nodes
    var button = document.querySelector('[aria-label="site"] button');
    var menu = button.nextElementSibling;

    // set initial (closed menu) states
    button.setAttribute('aria-expanded', 'false');
    button.hidden = false;
    if (mqm.matches) {
        menu.hidden = true;
    }

    $( window ).resize(function() {
        if (window.matchMedia('(min-width: 620px)').matches) {
            menu.hidden = false;
        }
    });

    button.addEventListener('click', function() {
        // toggle menu visibility
        var expanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', String(!expanded));
        menu.hidden = expanded;
    });

})();

$(document).ready(function() {
    'use strict';

    // feature.js
    if (feature.svg) {
        $('html').addClass('svg');
    }

     //filtre
     $('.label-group-tipus .checkbox:nth-child(8)').addClass('amagar-llista');
     $('.amagar-llista').nextAll().hide();
     $( ".veure" ).click(function(event) {
        event.preventDefault();
        $('.amagar-llista').nextAll().show();
        $(this).hide();
        return false;
      });
    if($('.label-group-tipus input[type="checkbox"]:checked').length>0){
        $('.amagar-llista').nextAll().show();
        $('.veure').hide();
    }

    $('.label-group-fabricants .checkbox:nth-child(8)').addClass('amagar-llista-fab');
     $('.amagar-llista-fab').nextAll().hide();
     $( ".veure-fab" ).click(function(event) {
        event.preventDefault();
        $('.amagar-llista-fab').nextAll().show();
        $(this).hide();
        return false;
      });
    if($('.label-group-fabricants input[type="checkbox"]:checked').length>0){
        $('.amagar-llista-fab').nextAll().show();
        $('.veure-fab').hide();
    }
    $('.filters-2 input[type="checkbox"], .filters-2 select').on('change',function(){
      $(this).closest('form').submit();

    });
    $( ".aplicar" ).click(function() {
      $('.filters-1').addClass('tancar-form');
    });
    $( ".filtrar-form" ).click(function() {
      $('.filters-1').removeClass('tancar-form');
    });

    $('.joguines').submit(function(e){
            e.preventDefault();

            $.ajax({
                type: "GET",
                url: catalogUrlBase + '/',
                data: $('.joguines').serialize(),
                success: function(data) {
                    $(".container-2").html(data);
                    //catalogRemoveLoader();
                    //$("#catalogContainer #resultats").fadeIn();
                }
            });
            return false;
      });

    $('.container-2').on('click', '.nav-buttons a, .search-tools a', function(e){
            e.preventDefault();
            var url = $(this).attr('href');
            $.ajax({
                type: "GET",
                url: url,
                success: function(data) {
                    $(".container-2").html(data);
                    //catalogRemoveLoader();
                    //$("#catalogContainer #resultats").fadeIn();
                }
            });
            return false;
      });

    $('.fabr-form').submit(function(e){
            e.preventDefault();

            $.ajax({
                type: "GET",
                url: catalogUrlBase + '/',
                data: $('.fabr-form').serialize(),
                success: function(data) {
                    $(".container-fabricants").html(data);
                    //catalogRemoveLoader();
                    //$(".container-3").fadeIn();
                }
            });
            return false;
    });

     //$(".js-example-basic-multiple").select2();
    $(".js-example-basic-multiple").select2({
        placeholder: "Escull un fabricant"
    });


    // hamburger
    var $hamburger = $(".hamburger");
    $hamburger.on("click", function(e) {
      $hamburger.toggleClass("is-active");
      $('.menu').toggleClass("js-open");
      $('body').toggleClass("js-menu-open");
    });

    // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
    // bind a click event to the 'skip' link
    $(".skip-link").click(function(event){

        // strip the leading hash and declare
        // the content we're skipping to
        var skipTo="#"+this.href.split('#')[1];

        // Setting 'tabindex' to -1 takes an element out of normal
        // tab flow but allows it to be focused via javascript
        $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

            // when focus leaves this element,
            // remove the tabindex attribute
            $(this).removeAttr('tabindex');

        }).focus(); // focus on the content container
    });

    // Match Height
    $('.home-block').matchHeight();
    //$('.fabr').matchHeight();
    $('.conc').matchHeight();
    $('.nav-concurs a').matchHeight();
    $('.blog-item').matchHeight();

    // canvi svg per png
    if (!feature.svg) {
        var imgs = document.getElementsByTagName('img');
        var endsWithDotSvg = /.*\.svg$/;
        var i = 0;
        var l = imgs.length;
        for (; i !== l; ++i) {
            if (imgs[i].src.match(endsWithDotSvg)) {
                imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
            }
        }
    }

    // smooth scroll top
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
          $(".top").fadeIn();
      } else {
          $(".top").fadeOut();
      }
    });
    $(function() {
      $('a[href*="#"]:not([href="#"],[href="#cercar"],[href="#filtrar"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    });

    // sliders
    $('.slider').slick({
        dots: true,
        autoplay: true,
        autoplaySpeed: 12000
    });

    // sticky header
    var lastScrollTop = 0;
    $(window).scroll(function(event){
        var st = $(this).scrollTop();
        //console.log(st + 'd');
        if (st > lastScrollTop && st > 20){
            $('.main-header').addClass('sticky');
        } else if (st < 93){
            $('.main-header').removeClass('sticky');
        }
        lastScrollTop = st;
    });

    // appendAround (https://github.com/filamentgroup/AppendAround)
    //$('.carta-reis').appendAround();
    //$('.s').appendAround();
    //$('.filtres').appendAround();
    //$('.search').appendAround();

    // galeria
    var $grid = $('.block-list--gallery').masonry({
      initLayout: false,
      itemSelector: '.block-list--gallery li'
    });
    $grid.masonry( 'on', 'layoutComplete', function() {

    });
    $grid.masonry();

    //$('.block-list--gallery').masonry({
      //itemSelector: '.block-list--gallery li'
    //});


    /* 4. Tab Interface (http://heydonworks.com/practical_aria_examples/#tab-interface) */

    // The class for the container div

    var $container = '.tab-interface';

    // The setup

    $($container +' ul').attr('role','tablist');
    $($container +' [role="tablist"] li').attr('role','presentation');
    $('[role="tablist"] a').attr({
        'role' : 'tab',
        'tabindex' : '-1'
    });

    // Make each aria-controls correspond id of targeted section (re href)

    $('[role="tablist"] a').each(function() {
      $(this).attr(
        'aria-controls', $(this).attr('href').substring(1)
      );
    });

    // Make the first tab selected by default and allow it focus

    // $('[role="tablist"] li:first-child a').attr({
    //     'aria-selected' : 'true',
    //     'tabindex' : '0'
    // });

    // Make each section focusable and give it the tabpanel role

    $($container +' section').attr({
      'role' : 'tabpanel'
    });

    // Make first child of each panel focusable programmatically

    $($container +' section > *:first-child').attr({
        'tabindex' : '0'
    });

    // Make all but the first section hidden (ARIA state and display CSS)

    // $('[role="tabpanel"]:not(:first-of-type)').attr({
    //   'aria-hidden' : 'true'
    // });

    // Make all hidden (ARIA state and display CSS)

    $('[role="tabpanel"]').attr({
      'aria-hidden' : 'true'
    });


    // Change focus between tabs with arrow keys

    $('[role="tab"]').on('keydown', function(e) {

      // define current, previous and next (possible) tabs

      var $original = $(this);
      var $prev = $(this).parents('li').prev().children('[role="tab"]');
      var $next = $(this).parents('li').next().children('[role="tab"]');
      var $target;

      // find the direction (prev or next)

      switch (e.keyCode) {
        case 37:
          $target = $prev;
          break;
        case 39:
          $target = $next;
          break;
        default:
          $target = false
          break;
      }

      if ($target.length) {
          $original.attr({
            'tabindex' : '-1',
            'aria-selected' : null
          });
          $target.attr({
            'tabindex' : '0',
            'aria-selected' : true
          }).focus();
      }

      // Hide panels

      $($container +' [role="tabpanel"]')
        .attr('aria-hidden', 'true');

      // Show panel which corresponds to target

      $('#' + $(document.activeElement).attr('href').substring(1))
        .attr('aria-hidden', null);

    });

    // Handle click on tab to show + focus tabpanel

    $('[role="tab"]').on('click', function(e) {

      e.preventDefault();

      // remove focusability [sic] and aria-selected

      $('[role="tab"]').attr({
        'tabindex': '-1',
        'aria-selected' : null
        });

      // replace above on clicked tab

      $(this).attr({
        'aria-selected' : true,
        'tabindex' : '0'
      });

      // Hide panels

      $($container +' [role="tabpanel"]').attr('aria-hidden', 'true');

      // show corresponding panel

      $('#' + $(this).attr('href').substring(1))
        .attr('aria-hidden', null);

    });

});
